import Swiper from 'swiper';
import { EffectFade } from 'swiper/modules';

window.addEventListener('load', function () {

  if( document.body.classList.contains('single-gallery')) {

    // IMAGES SLIDER

  	const gallerySliders = document.querySelectorAll('.gallery-slider');
    
    gallerySliders.forEach((slider) => {

      const gallerySlider = new Swiper(slider, {
        modules:[EffectFade],
        speed:400,
        slidesPerView: 1,
        allowTouchMove:false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
      });

      const buttons = slider.querySelectorAll('.slider-nav button');
      
      const handleBtnClick = (e) => {
        const target = e.currentTarget;
        const index = target.dataset.index;
        buttons.forEach((button) => {
          button.classList.remove('active');
        })
        target.classList.add('active');
        gallerySlider.slideTo(index);
      }

      buttons.forEach((button,index) => {
        button.addEventListener('click', handleBtnClick);
        if(index == 0){
          button.classList.add('active');
        }
      })

    });

  }

}, false);