import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

window.addEventListener('load', function () {

  if( document.body.classList.contains('post-type-archive-gallery')) {

    console.log('yooo')

    // IMAGES SLIDER

  	const categorySliders = document.querySelectorAll('.category-slider');
    
    categorySliders.forEach((slider) => {

      const gallerySlider = new Swiper(slider, {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        speed:400,
        navigation: {
          nextEl: ".slider-next",
          prevEl: ".slider-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      });

    });

  }

}, false);